























































import Vue from "vue";
import { Component } from "vue-property-decorator";
import MessageSummary from "../../ApiClient/MessageSummary";
import SessionSummary from "../../ApiClient/SessionSummary";
import MessageList from "@/components/messagelist.vue";
import MessageView from "@/components/messageview.vue";
import SessionList from "@/components/sessionlist.vue";
import SessionView from "@/components/sessionview.vue";
import VersionInfo from "@/components/versionInfo.vue";
import HubConnectionManager from "@/HubConnectionManager";
import ServerStatus from "@/components/serverstatus.vue";
import SettingsDialog from "@/components/settingsdialog.vue";
import HubConnectionStatus from "@/components/hubconnectionstatus.vue";
import { Splitpanes, Pane } from 'splitpanes';
import 'splitpanes/dist/splitpanes.css';

@Component({
    components: {
        messagelist: MessageList,
        messageview: MessageView,
        sessionlist: SessionList,
        sessionview: SessionView,
        hubconnstatus: HubConnectionStatus,
        serverstatus: ServerStatus,
        settingsdialog: SettingsDialog,
        splitpanes: Splitpanes,
        pane: Pane,
        VersionInfo
    }
})
export default class Home extends Vue {
    selectedMessage: MessageSummary | null = null;
    selectedSession: SessionSummary | null = null;

    connection: HubConnectionManager | null = null;

    settingsVisible: boolean = false;

    selectedMessageChanged(selectedMessage: MessageSummary | null) {
        this.selectedMessage = selectedMessage;
    }

    selectedSessionChanged(selectedSession: SessionSummary | null) {
        this.selectedSession = selectedSession;
    }

    get messageListPaneSize(): number {

        var storedValue = window.localStorage.getItem("messagelist-panelsize");
        if (storedValue) {
            return Number(storedValue);
        }

        return 40;
    }

    set messageListPaneSize(value: number) {
        window.localStorage.setItem("messagelist-panelsize", value.toString());
    }

    get sessionListPaneSize(): number {

        var storedValue = window.localStorage.getItem("sessionlist-panelsize");
        if (storedValue) {
            return Number(storedValue);
        }

        return 40;
    }

    set sessionListPaneSize(value: number) {
        window.localStorage.setItem("sessionlist-panelsize", value.toString());
    }

    constructor() {
        super();
    }

    async mounted() {
        this.connection = new HubConnectionManager("hubs/notifications")
        this.connection.start();
    }

    destroyed() {
        if (this.connection) {
            this.connection.stop();
        }
    }
}
