













































































import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import SessionsController from "../ApiClient/SessionsController";
import SessionSummary from "../ApiClient/SessionSummary";
import * as moment from "moment";
import HubConnectionManager from "../HubConnectionManager";
import sortedArraySync from "../sortedArraySync";
import { Mutex } from "async-mutex";
import ConfirmationDialog from "@/components/confirmationdialog.vue";
import { ElTable } from "element-ui/types/table";
import PagedResult, { EmptyPagedResult } from "@/ApiClient/PagedResult";
import Messagelistpager from "@/components/messagelistpager.vue";
import ClientController from "@/ApiClient/ClientController";

@Component({
  components: {
    Messagelistpager,
    confirmationdialog: ConfirmationDialog,
  },
})
export default class SessionList extends Vue {
  constructor() {
    super();
    this.pagedServerMessages = EmptyPagedResult<SessionSummary>();
  }

  page: number = 1;
  pageSize: number = 25;
  public pagedServerMessages: PagedResult<SessionSummary>|undefined = undefined;

  @Prop({ default: null })
  connection: HubConnectionManager | null = null;

  sessions: SessionSummary[] = [];
  error: Error | null = null;
  selectedsession: SessionSummary | null = null;
  loading: boolean = true;
  private mutex = new Mutex();

  handleCurrentChange(session: SessionSummary | null): void {
    this.selectedsession = session;
    this.$emit("selected-session-changed", session);
  }

    async handlePaginationCurrentChange(page: number) {
    this.page = page;
    await this.refresh();
  }

  async handlePaginationPageSizeChange(pageSize: number) {
    this.pageSize = pageSize;
    await this.refresh();
  }

  formatDate(
    row: number,
    column: number,
    cellValue: Date,
    index: number
  ): string {
    return (<any>moment)(cellValue).format("YYYY-MM-DD HH:mm:ss");
  }

  selectSession(session: SessionSummary) {
    (<ElTable>this.$refs.table).setCurrentRow(session);
    this.handleCurrentChange(session);
  }

  async deleteSelected() {
    if (this.selectedsession == null) {
      return;
    }

    this.loading = true;
    let sessionToDelete = this.selectedsession;

    let nextIndex = this.sessions.indexOf(sessionToDelete) - 1;
    if (nextIndex >= 0) {
      this.selectSession(this.sessions[nextIndex]);
    }

    try {
      await new SessionsController().delete(sessionToDelete.id);
      this.refresh();
    } catch (e: any) {
      this.$notify.error({
        title: "Delete Session Failed",
        message: e.message,
      });
    } finally {
      this.loading = false;
    }
  }

  async clear() {
    this.loading = true;
    try {
      await new SessionsController().deleteAll();
      this.refresh();
    } catch (e: any) {
      this.$notify.error({
        title: "Clear Sessions Failed",
        message: e.message,
      });
    } finally {
      this.loading = false;
    }
  }

  async refresh(silent: boolean = false) {
    var unlock = await this.mutex.acquire();

    try {
      this.error = null;
      this.loading = !silent;

      this.pagedServerMessages = await new SessionsController().getSummaries( 
        this.page,
        this.pageSize);
      sortedArraySync(
        this.pagedServerMessages.results,
        this.sessions,
        (a: SessionSummary, b: SessionSummary) => a.id == b.id
      );

      if (
        !this.sessions.some(
          (m) => this.selectedsession != null && m.id == this.selectedsession.id
        )
      ) {
        this.handleCurrentChange(null);
      }
    } catch (e: any) {
      this.error = e;
    } finally {
      this.loading = false;
      unlock();
    }
  }

  async mounted() {
    this.refresh(false);
  }

  async created() {
    await this.initPageSizeProps();
  }

  private async initPageSizeProps() {
    const defaultPageSize = 25;
    let client = await new ClientController().getClient();
    this.pageSize = client.pageSize || defaultPageSize;
  }

  @Watch("connection")
  async onConnectionChanged() {
    if (this.connection) {
      this.connection.on("sessionschanged", () => {
        this.refresh(true);
      });
      this.connection.addOnConnectedCallback(() => this.refresh(true));
    }
  }
}

