










































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";
import MessagesController from "../ApiClient/MessagesController";
import MessageSummary from "../ApiClient/MessageSummary";
import Message from "../ApiClient/Message";
import MessageEntitySummary from "../ApiClient/MessageEntitySummary";
import MessageWarning from "../ApiClient/MessageWarning";
import Headers from "@/components/headers.vue";
import MessageViewHtml from "@/components/messageviewhtml.vue";
import MessageviewAttachments from "@/components/messageviewattachments.vue";
import MessagePartsSource from "@/components/messagepartsource.vue";
import MessageSource from "@/components/messagesource.vue";
import { Tree } from "element-ui";
import { MessageBoxInputData } from 'element-ui/types/message-box';
import ServerController from '../ApiClient/ServerController';

@Component({
    components: {
        headers: Headers,
        "messageview-html": MessageViewHtml,
        messageviewattachments: MessageviewAttachments,
        messagepartsource: MessagePartsSource,
        messagesource: MessageSource
    }
})
export default class MessageView extends Vue {
    constructor() {
        super();
    }

    @Prop({})
    messageSummary: MessageSummary | null = null;
    message: Message | null = null;
    selectedPart: MessageEntitySummary | null = null;
    warnings: MessageWarning[] = [];

    error: Error | null = null;
    loading = false;


    isRelayInProgress: boolean = false;
    isRelayAvailable: boolean = false;

    @Watch("messageSummary")
    async onMessageSummaryChange(
        value: MessageSummary | null,
        oldValue: MessageSummary | null
    ) {
        await this.loadMessage();
    }

    async loadMessage() {
        this.error = null;
        this.loading = true;
        this.message = null;
        this.selectedPart = null;

        try {
            if (this.messageSummary != null) {
                this.message = await new MessagesController().getMessage(
                    this.messageSummary.id
                );
                if (this.$refs.partstree) {
                    (<Tree>this.$refs.partstree).setCurrentNode(
                        (<Tree>this.$refs.partstree).getNode(0)
                    );
                }
                this.setWarnings();

                if (this.messageSummary.isUnread) {
                    var currentMessageSummary = this.messageSummary;
                    setTimeout(async () => {
                        if (
                            this.messageSummary != null &&
                            currentMessageSummary.id == this.messageSummary.id
                        ) {
                            try {
                                await new MessagesController().markMessageRead(
                                    this.messageSummary.id
                                );
                            } catch (e) {
                                console.error(e);
                            }
                        }
                    }, 2000);
                }
            }
        } catch (e: any) {
            this.error = e;
        } finally {
            this.loading = false;
        }
    }

    async setWarnings() {
        var result: MessageWarning[] = [];

        if (this.message != null) {
            var parts = this.message.parts;
            this.getWarnings(parts, result);
        }
        this.warnings = result;
    }

    getWarnings(parts: MessageEntitySummary[], result: MessageWarning[]) {
        for (let part of parts) {
            for (let warning of part.warnings) {
                result.push(warning);
            }

            this.getWarnings(part.childParts, result);
        }
    }

    isLeaf(value: MessageEntitySummary[] | MessageEntitySummary) {
        return (
            !(value as MessageEntitySummary[]).length &&
            !(value as MessageEntitySummary).childParts.length
        );
    }

    onPartSelection(part: MessageEntitySummary | null) {
        this.selectedPart = part;
    }

    async relay() {
        if (this.messageSummary == null) {
            return;
        }

        let emails: string[];

        try {

            let dialogResult = <MessageBoxInputData>await this.$prompt('Email address(es) to relay to (separate multiple with ,)', 'Relay Message', {
                confirmButtonText: 'OK',
                inputValue: this.messageSummary.to,
                cancelButtonText: 'Cancel',
                inputPattern: /[^, ]+(, *[^, ]+)*/,
                inputErrorMessage: 'Invalid email addresses'
            });

            emails = (<string>dialogResult.value).split(",").map(e => e.trim());
        } catch {
            return;
        }

        try {
            this.isRelayInProgress = true;
            await new MessagesController().relayMessage(this.messageSummary.id, { overrideRecipientAddresses: emails });

            this.$notify.success({ title: "Relay Message Success", message: "Completed OK" });
        } catch (e: any) {
            var message = e.response?.data?.detail ?? e.sessage;

            this.$notify.error({ title: "Relay Message Failed", message: message });
        } finally {
            this.isRelayInProgress = false;
        }
    }

    async download() {
        if (this.messageSummary == null) {
            return;
        }
        window.open(
            new MessagesController().downloadMessage_url(this.messageSummary.id)
        );
    }

    async mounted() {
        await this.loadMessage();
        this.isRelayAvailable = !!await (await new ServerController().getServer()).relayOptions.smtpServer;
    }

    async destroyed() { }

    get headers() {
        return this.message != null ? this.message.headers : [];
    }

    get selectedPartHeaders() {
        return this.selectedPart != null ? this.selectedPart.headers : [];
    }
}
