










import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import HubConnectionManager from "../HubConnectionManager";
import { Mutex } from "async-mutex";
import ServerController from "../ApiClient/ServerController";
import Server from "../ApiClient/Server";

@Component
export default class ServerStatus extends Vue {

    constructor() {
        super();
    }

    @Prop({ default: null })
    connection: HubConnectionManager | null = null;

    error: Error | null = null;
    private mutex = new Mutex();
    loading: boolean = true;
    server: Server | null = null;

    async refresh(silent: boolean=false) {
        var unlock = await this.mutex.acquire();

        try {
            this.error = null;
            this.loading = !silent;

            this.server = await new ServerController().getServer();
        } catch (e: any) {
            this.error = e;
        } finally {
            this.loading = false;
            unlock();
        }
    }

    async stop() {
        let serverUpdate: Server = { ... this.server } as Server;
        serverUpdate.isRunning = false;

        await new ServerController().updateServer(serverUpdate);
    }

    async start() {
        let serverUpdate: Server = { ... this.server } as Server;
        serverUpdate.isRunning = true;
        await new ServerController().updateServer(serverUpdate);
    }

    async mounted() {
        await this.refresh();
    }

    @Watch("connection")
    onConnectionChanged() {
        if (this.connection) {
            this.connection.on("serverchanged", async () => {
                await this.refresh();
            });

            this.connection.addOnConnectedCallback(() => this.refresh());
        }
    }

    showSettings() {
        this.$emit('showsettings');
    }
}
